<template>
<div>
    <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <div class="d-flex align-items-center flex-wrap mr-1">
                    <div class="d-flex align-items-baseline flex-wrap mr-5">
                        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 font-size-sm">
                            <li class="breadcrumb-item">
                                {{school_year_list.length}} Total
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="card-toolbar card-actions">
                        <a href="Javascript:void(0)" v-if="user.user_type == 'school'" @click="updateDialog" class="btn font-weight-bolder add-action mr-3 add-button-margin">
                            <i class="fas fa-plus-circle icon-md"></i>Update School Year
                        </a>
                    </div>
                </div>
        </div>
    </div>
    <div class="d-flex flex-column-fluid">
        <div class="container">
            <div class="card card-custom">
                <div class="card-body">
                   <div class="table-responsive">
					   <DataTable  class="p-datatable-lg" ref="dt"  :value="school_year_list"
                        dataKey="id" v-model:filters="vm.filter_list"  filterDisplay="menu"  :loading="loading" responsiveLayout="stack" breakpoint="960px"
                        :globalFilterFields="['name']"
					   	:paginator="true" :rows="10"
                        :resizableColumns="true" columnResizeMode="fit"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,20,50]">
                            <template #header>
                            <div class="card-header  p-0 pt-0 border-0">
                                <h3 class="card-title  u-flex">
                                    <span class="card-label fw-bolder fs-3 mb-1 u-flex-1">School Year List</span>
                                    <div class="p-d-flex p-jc-between me-2 searchbar-width">
                                        <span class="p-input-icon-left text-blue">
                                            <i class="pi pi-search" />
                                            <InputText class="tableSearch" v-model="vm.filter_list['global'].value" placeholder="Search..." />
                                        </span>
                                    </div>
                                    <a href="Javascript:void(0)" @click="getSchoolYearList()" class="ml-2 btn btn-sm btn-light-primary fw-600 me-2">
                                                <i class="pi pi-search  search-icon" />
                                    </a>
                                </h3>
                            </div>
                            </template>
							<Column field="start_date" header="Start Date" :sortable="true" aria-placeholder="off" aria-autocomplete="off"></Column>
							<Column field="end_date" header="End Date" :sortable="true" aria-placeholder="off" aria-autocomplete="off"></Column>

                            <Column field="id" header="">
                                            <template #body="slotProps">
                                                <a href="Javascript:void(0)" @click="selectReport(slotProps)" class="btn btn-sm btn-primary btn-icon  float-right  action-edit actions-table ml-2" v-tooltip.top="'Reports'" >
                                                    <i class="pi pi-chart-bar"></i>
                                                </a>
                                                <a href="Javascript:void(0)" @click="deletePrompt(slotProps)" class="btn btn-sm btn-clean btn-icon action-delete  float-right  actions-table" v-tooltip.top="'Delete School Year'">
                                                    <span class="svg-icon svg-icon-md">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"/>
                                                                <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </a>
                                                <a  href="Javascript:void(0)" @click="editDialog(slotProps)" class="btn btn-sm btn-clean btn-icon mr-2 action-edit float-right actions-table" v-tooltip.top="'Edit School Year'">
                                                    <span class="svg-icon svg-icon-md">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"/>
                                                                <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                                                                <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </a>
                                            </template>
                                        </Column>
                            <template #loading>
                                Loading customers data. Please wait.
                            </template>
                            <template #empty>
                                No users found.
                            </template>
						</DataTable>
					</div>
                </div>
            </div>
        </div>
    </div>
    <SchoolYearCreateOrEdit :type="type" :add_school_year_watcher="add_school_year_watcher" :selected_school_year="selected_school_year"></SchoolYearCreateOrEdit>
    <ConfirmDialog></ConfirmDialog>
</div>
</template>
<script>
import  SchoolYearGetList  from '@/composables/school-year/SchoolYearGetList'
import  SchoolYearDelete  from '@/composables/school-year/SchoolYearDelete'

import { defineComponent, ref, watch, reactive, onMounted, inject, computed, provide } from 'vue'        
import { useStore  } from 'vuex'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import Tag from 'primevue/tag';
import SchoolYearCreateOrEdit from './SchoolYearCreateOrEdit.vue'

import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast";
import ConfirmDialog from 'primevue/confirmdialog'

export default defineComponent({
    components: {  SchoolYearCreateOrEdit, DataTable, Column, InputText, ConfirmDialog },
    setup() {
        const constant = inject('Constant')
		const http = inject('Http')
        const store = useStore()
        const confirm = useConfirm();
        const toast = useToast();

        const vm = reactive({
            filter_list: { 'global': {value: null, matchMode: FilterMatchMode.CONTAINS} }
        })

        const selected_school_year = ref([])
        const add_school_year_watcher = ref(1)
        const type = ref('type')

        const { loading_school_year, school_year_list, getSchoolYearList } = SchoolYearGetList(http, constant, store)
        const { delete_status, deleteSchoolYear } = SchoolYearDelete(http, constant, toast)

        function deletePrompt(s) {
            selected_school_year.value = s.data
            confirm.require({
                message: `Are you sure you want to delete this school year? All data associated will be lost!`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deleteNow()
                },
                reject: () => {
                }
            });
            return {deletePrompt};
        } 
        function deleteNow() {
            deleteSchoolYear(selected_school_year.value.id)
        }
        function updateDialog() {
            add_school_year_watcher.value++
            type.value = 'create'
        }
        function editDialog(s) {
            selected_school_year.value = s.data
            add_school_year_watcher.value++
            type.value = 'edit'
        }

        watch(delete_status, (n, o) => {
            if (delete_status.value)
                getSchoolYearList()
        });

        onMounted(() => {
            getSchoolYearList()
        })
        provide('SchoolYear', { getSchoolYearList })
        return {
            vm,
            school_year_list, loading_school_year,
            getSchoolYearList,
            editDialog, deletePrompt,
            updateDialog, selected_school_year, add_school_year_watcher,
            user: computed(() => store.getters['UserModule/GET_USER']),
            type

        }
    },
})
</script>
