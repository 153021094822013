import { ref } from 'vue'
export default function SchoolYearGetList(http, constant, store) {
    let loading_school_year = ref(false)
    let school_year_list = ref([])

    async function getSchoolYearList() {
        loading_school_year.value = true;
        school_year_list.value = []
        try {
            const response = await http.post(constant.SCHOOLS_YEAR_GET_LIST, [])
            loading_school_year.value = false
            school_year_list.value = response.data.data.list
            store.commit('SchoolYearModule/SET_SHOOL_YEAR_LIST', school_year_list.value)
            store.commit('UserModule/SET_ACTIVE_SCHOOL_YEAR', response.data.data.school_year)

        } catch (err) {
            loading.value = false;
        }
    }
    return {
        loading_school_year, school_year_list, getSchoolYearList
    }
}