<template>

    <Sidebar  v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
        <form  ref="schoolYearData" @submit.prevent="create"  action="post"  novalidate >
            <div class="rpanel-title text-capitalize">
                <div class="flex">
                    <div class="w-100">
                        <p>School Year</p>
                        <small>Update active school year</small>
                    </div>
                    <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
                </div>
            </div>
            <div class="drawer-content">
                <div  class="p-10  row  pt-0 mt-0">
                    <div class="p-field col-6 form-group">
                        <p class="w-100" >Start date <span class="text-danger">*</span></p>
                        <Calendar :manualInput="false"  :showOnFocus="true" id="monthpicker" name="start_date" v-model="start_date" view="month" dateFormat="mm/yy" class="w-100"  />
                    </div>
                    <div class="p-field   col-6  form-group">
                        <p class="w-100" >End date <span class="text-danger">*</span></p>
                    <Calendar :manualInput="false" :showOnFocus="true" id="monthpicker" name="end_date"   v-model="end_date" view="month" dateFormat="mm/yy" class="w-100"  />
                    </div>
                </div>
            </div>
            <div class="r-panel-footer drawer-footer--v2">
                <div class="r-panel-footer-content">
                    <button type="button" :disabled="create_status" @click="display = false" class="cursor-pointer btn btn-sm btn-secondary om-add-contact-toggle mr-2">Cancel</button>
                    <button type="submit" :disabled="create_status" class="btn btn-sm btn-info save mr-2">
                        <span  v-if="!create_status">  Save</span>
                        <span v-else><i class="fas fa-spinner fa-spinner"></i> Saving...</span>
                    </button>
                </div>
            </div>
        </form>
    </Sidebar>
</template>
<script>

import  SchoolYearCreate  from '@/composables/school-year/SchoolYearCreate'
import Calendar from 'primevue/calendar';

import { defineComponent, ref, watch,  inject} from 'vue'
import Dialog from 'primevue/dialog';
import { useStore  } from 'vuex'
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm"   
import Sidebar from 'primevue/sidebar';
import moment from 'moment'
export default defineComponent({
    props: {
        add_school_year_watcher: {
            tpe: Number,
            default: 1
        },
        selected_school_year: {
            default: []
        },
        type: {
            default: 'create'
        }
    },
    components: { Sidebar, Calendar  },
    setup(props) {
        
        const constant = inject('Constant')
		const http = inject('Http')
        const { getSchoolYearList } = inject('SchoolYear')
        const toast = useToast()
        const confirm = useConfirm();
        const store = new useStore()

        let display = ref(false)
        let name = ref(false)
        let selected_teacher = ref('')
        const start_date = ref()
        const end_date = ref()
        const schoolYearData = ref('')
        const { create_status, createSchoolYear } = SchoolYearCreate(http, constant, toast)

        function create() {
            createSchoolYear(props.selected_school_year.id, props.type, schoolYearData.value)
        }
        watch(create_status, (n, o) => {
            if (create_status.value == false) {
                display.value = false
                getSchoolYearList()
            }
        });
        watch(()=>props.add_school_year_watcher, (n) => {
            display.value = true
            name.value = ''
            if (props.type == 'edit') {
                start_date.value = props.selected_school_year.start_date
                end_date.value = props.selected_school_year.end_date
            }
        })
        return {
            display, name, create_status,  create, start_date, end_date, schoolYearData

        }
    },
})
</script>

<style lang="scss" scoped>


::v-deep(.multiselect-custom) {
    .p-multiselect-label:not(.p-placeholder) {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .country-item-value {
        padding: .25rem .5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: .5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);

        img.flag {
            width: 17px;
        }
    }
}
</style>