import { ref } from 'vue'
export default function SchoolYearCreate(http, constant, toast) {
    const create_status = ref(false)
    async function createSchoolYear(id, view, data) {
        try {
            create_status.value = true

            const f = new FormData(data)

            if (view  == 'edit')  
                f.append('id', id)

            const response = await http.post(view  == 'edit' ? constant.SCHOOL_YEAR_EDIT :  constant.SCHOOL_YEAR_CREATE, f)
            create_status.value = false
            toast.add({severity:'success', summary: 'Success', detail:'School Year was successfully created/updated.', life: 3000});

        } catch (err) {
            create_status.value = false
            console.log(err.message)
        }
    }
    return {
        create_status,
        createSchoolYear
    }
}