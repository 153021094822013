import { ref } from 'vue'
export default function SchoolYearDelete(http, constant, toast) {
    const delete_status = ref(false)
    async function deleteSchoolYear(id) {
        try {
            delete_status.value = true
            const f = new URLSearchParams()
            f.append('id', id)
            const response = await http.post(constant.SCHOOL_YEAR_DELETE, f)
            toast.add({severity:'success', summary: 'Success', detail:'School year was successfully deleted.', life: 3000});

            delete_status.value = false
        } catch (err) {
            delete_status.value = false
            console.log(err.message)
        }
    }
    return {
        delete_status,
        deleteSchoolYear
    }
}